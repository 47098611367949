import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";

export default function MobileNav({ os }) {
  const handleAppRedirect = () => {
    if (os === "android")
      window.location =
        "https://play.google.com/store/apps/details?id=in.learningx.club_app";
    else if (os === "iphone")
      window.location =
        "https://apps.apple.com/in/app/learningx-college-clubs/id6612034727";
    else alert("Sorry, We are working on ios app.");
  };

  return (
    <Menu inverted fixed="top">
      <Menu.Item header as={Link} to={"/"}>
        <img src="/logo.png" alt="logo" style={{ marginRight: 15 }} />
        <span style={{ color: "black" }}>Club-Chat</span>
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item onClick={handleAppRedirect}>
          <Icon name="google play" color="blue" size="large" />
        </Menu.Item>
        <Menu.Item>
          <Dropdown item icon="sidebar" style={{ color: "blue" }}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={"a"}
                href="/about"
                icon="info"
                text="About us"
              />
              <Dropdown.Item
                as={"a"}
                href="/privacy-policy"
                icon="privacy"
                text="Privacy policy"
              />
              <Dropdown.Item
                as={"a"}
                href="/terms-conditions"
                icon="file code"
                text="Terms &amp; condition"
              />
              <Dropdown.Item
                as={"a"}
                href="https://m.clubchat.live/login"
                icon="mobile"
                text="Open Mobile web version"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}
