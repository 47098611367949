import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import {
  Button,
  Confirm,
  Dropdown,
  Icon,
  Image,
  Label,
  Menu,
  Search,
} from "semantic-ui-react";
import { fetchUserApi } from "../../app/apiRequest/profileService";
import { openModal } from "../../app/common/modals/modalReducer";
import { logoutUser } from "../auth/authReducer";
import { listenToCurrentUserProfile } from "../profile/profileReducer";
import { fetchSearchQueries } from "../../app/apiRequest/extraService";
import _ from "lodash";
import {
  cleanSearchQuery,
  finishSearch,
  startSearch,
  updateSelection,
} from "../search/searchReducer";
import { fetchClubFeedApi } from "../../app/apiRequest/clubService";
import { YOUR_CLUBS_FETCHED } from "../club/clubReducer";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function SignedInMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentLocation, user, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const { activeMenu } = useSelector((state) => state.async);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { loading, results, value, queries } = useSelector(
    (state) => state.search
  );
  const { todaySessions } = useSelector((state) => state.reminder);
  const [openConfirm, setOpenConfirm] = useState(false);
  // const { yourClubs } = useSelector((state) => state.club);

  const timeoutRef = React.useRef();

  // eslint-disable-next-line
  var a = [];
  a.push(...queries);

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch(startSearch(data.value));
      a.unshift({
        title: data.value,
      });
      if (a.length > queries.length + 1) a.shift();

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch(cleanSearchQuery());
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), "i");
        const result = a.filter((el) => re.test(el.title));

        dispatch(finishSearch(result));
      }, 300);
    },
    [a, dispatch, queries]
  );

  const handleKeyPress = (e, data) => {
    if (e.key === "Enter") {
      dispatch(updateSelection(value));
      history.push(`/search?club=${value}`);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentLocation.pathname !== "/search") {
      dispatch(cleanSearchQuery());
    }
  }, [currentLocation.pathname, dispatch]);

  useEffect(() => {
    if (currentUserProfile) return;
    dispatch(fetchUserApi(user));
    dispatch(fetchSearchQueries());
  }, [dispatch, user, currentUserProfile]);

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(fetchClubFeedApi("?members=" + user)).then(() => {
      dispatch({ type: YOUR_CLUBS_FETCHED });
    });
  }, [dispatch, user, isAuthenticated]);

  // useEffect(() => {
  //   if (yourClubs.length === 0 || todaySessions > 0) return;
  //   for (let i = 0; i < yourClubs.length; i++) {
  //     var lastMomentOfToday = new Date();
  //     lastMomentOfToday.setHours(23, 59, 59, 999);
  //     dispatch(
  //       fetchClubSessionsApi(
  //         yourClubs[i]._id,
  //         `?startTime[$gte]=${new Date().toISOString()}&startTime[$lte]=${lastMomentOfToday.toISOString()}`
  //       )
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch, yourClubs]);

  const handleLogout = () => {
    if (currentUserProfile.azureId !== "") {
      window.open(
        "https://login.microsoftonline.com/common/oauth2/v2.0/logout"
      );
    }
    dispatch(logoutUser());
    dispatch(listenToCurrentUserProfile(null));
    history.push("/");
  };

  return (
    <>
      <Menu borderless fixed="top">
        <Menu.Item style={{ marginLeft: 15 }} header as={Link} to={"/"}>
          <img src="/logo.png" alt="logo" style={{ marginRight: 8 }} />
          <span style={{ color: "black" }}>Club-Chat</span>
        </Menu.Item>
        <Menu.Item header>{activeMenu}</Menu.Item>
        {currentLocation.pathname === "/feed" && (
          <Menu.Item>
            <Button
              color="blue"
              content="Create Post"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "CreatePostForm",
                    modalProps: {
                      userId: user,
                      displayName: currentUserProfile.displayName,
                      displayImg: currentUserProfile.userImg,
                      todo: "create",
                      postBy: "user",
                    },
                  })
                )
              }
            />
          </Menu.Item>
        )}
        {currentLocation.pathname === "/clubs" && (
          <Menu.Item>
            <Button
              color="blue"
              content="Create Club"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "CreateClubForm",
                    modalProps: { userId: user, todo: "create" },
                  })
                )
              }
            />
          </Menu.Item>
        )}
        <Menu.Item position="right">
          <Search
            fluid
            loading={loading}
            placeholder="Search ..."
            onResultSelect={(e, data) => {
              dispatch(updateSelection(data.result.title));
              history.push(`/search?club=${data.result.title}`);
            }}
            onSearchChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            results={results}
            value={value}
          />
        </Menu.Item>
        <Menu.Item />
        <Menu.Item as={NavLink} exact to="/reminder">
          <Icon name="calendar check" size="large" color="blue" />
          {todaySessions !== 0 && (
            <Label
              color="blue"
              size="mini"
              circular
              style={{ marginLeft: -10, marginBottom: 20 }}
            >
              {todaySessions}
            </Label>
          )}
        </Menu.Item>
        <Menu.Item>
          <Image avatar spaced="right" src={currentUserProfile?.userImg} />
          <Dropdown
            pointing="top left"
            text={
              <span style={{ color: "rgb(66,131,202)" }}>
                {currentUserProfile?.firstname} {currentUserProfile?.lastname}
              </span>
            }
          >
            <Dropdown.Menu size="huge">
              <Dropdown.Item
                text="My Profile"
                icon="user"
                as={NavLink}
                exact
                to={`/profile/${user}`}
              />
              <Dropdown.Item
                text="Help &amp; Support"
                icon="help"
                as={NavLink}
                exact
                to={`/help`}
              />
              <Dropdown.Item
                text="Sign out"
                icon="power"
                onClick={() => setOpenConfirm(true)}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Confirm
            open={openConfirm}
            size="mini"
            content="Log out of Club-Chat?"
            onCancel={() => setOpenConfirm(false)}
            onConfirm={handleLogout}
          />
        </Menu.Item>
        <Menu.Item />
        <Menu.Item />
        <Menu.Item />
      </Menu>
    </>
  );
}
