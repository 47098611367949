import React from "react";
import aboutImage from "./about.png";
import { useSelector } from "react-redux";

function Header() {
  const { isMobileDevice } = useSelector((state) => state.auth);
  return (
    <div className="mb-7">
      <div
        style={{
          backgroundImage: `url(${aboutImage})`,
          paddingTop: isMobileDevice ? 50 : 0,
        }}
        className={`flex flex-col md:flex-row h-screen w-full bg-cover bg-center ${
          isMobileDevice ? "justify-center items-center text-center" : ""
        }`}
      >
        {/* Left Section */}
        <div className="flex flex-col items-center justify-center gap-5 w-full md:w-2/5 mt-10 md:mt-0 px-5 order-2 md:order-1">
          <img
            src={"/assets/club_chat_mobile.png"}
            alt="ClubChat"
            className="max-w-[150px] sm:max-w-[200px] md:max-w-[225px] rounded-xl shadow-lg"
          />
          <p className="text-gay-100 text-center text-[20px] sm:text-lg px-5 sm:px-10 text-white">
            Discover academic clubs and events with ease, all in one
            place.
          </p>
        </div>

        {/* Right Section */}
        <div className="flex flex-col justify-center gap-5 w-full md:w-3/5 px-5 sm:px-16 md:px-24 order-1 md:order-2">
          {/* Headings */}
          <div className="text-center md:text-left w-full">
            <div className="w-full">
              <p className="text-[30px] sm:text-[32px] md:text-[48px] font-OpenSans leading-tight text-white">
                Create, Join, or Discover Academic Clubs
              </p>
              <p className="text-[18px] sm:text-[18px] md:text-[24px] text-gay-200 mt-2 sm:mt-4 text-white">
                Helping students stay connected with their academic clubs
                and events.
              </p>
            </div>
          </div>

          {/* Buttons Section */}
          <div className="flex flex-wrap justify-center md:justify-start gap-4 md:gap-8 mt-4 w-full">
            <a
              href="https://play.google.com/store/apps/details?id=in.learningx.club_app"
              className="group"
            >
              <div className="bg-gray-100 hover:bg-gray-200 rounded-[30px] w-[150px] sm:w-[200px] md:w-[269px] h-[50px] sm:h-[60px] md:h-[76px] flex items-center justify-center border shadow-sm hover:shadow-md transition-all duration-200">
                <img
                  className="w-[30px] sm:w-[40px] md:w-[64px] h-[30px] sm:h-[40px] md:h-[64px] mr-2"
                  src={"/assets/playstor.png"}
                  alt="Play Store"
                />
                <div className="text-black text-xs sm:text-sm md:text-base">
                  <p className="font-poppins text-center font-medium group-hover:text-gray-700">
                    Download on <br /> <strong>Google Play</strong>
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://apps.apple.com/in/app/clubchat-academic-club-more/id6612034727"
              className="group"
            >
              <div className="bg-gray-100 hover:bg-gray-200 rounded-[30px] w-[150px] sm:w-[200px] md:w-[269px] h-[50px] sm:h-[60px] md:h-[76px] flex items-center justify-center border shadow-sm hover:shadow-md transition-all duration-200">
                <img
                  className="w-[30px] sm:w-[40px] md:w-[64px] h-[30px] sm:h-[40px] md:h-[64px] mr-2"
                  src={"/assets/appstor.png"}
                  alt="App Store"
                />
                <div className="text-black text-xs sm:text-sm md:text-base">
                  <p className="font-poppins text-center font-medium group-hover:text-gray-700">
                    Download on <br /> <strong>App Store</strong>
                  </p>
                </div>
              </div>
            </a>
            <p className="text-[18px] sm:text-[18px] md:text-[18px] mt-2 sm:mt-4 text-white">
              ClubChat is Free to use
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
