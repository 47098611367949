import React from "react";

function Value() {
  return (
    <div className="flex flex-col items-center px-4 mb-4">
      <div className="flex items-center mt-24 mb-10 text-center">
        <div className="w-10 h-10 rounded-full bg-purple-800 mr-4"></div>
        <div className="text-purple-900 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold font-poppins">
          Our Values
        </div>
      </div>

      <div className="flex flex-col lg:flex-row bg-[rgba(81,59,131,0.4)] w-full lg:w-[70%] h-auto rounded-2xl text-black p-6 md:p-8 mt-5 items-center justify-center gap-6 lg:gap-12">
        <div className="relative flex items-center justify-center w-32 h-32">
          <div className="w-10 h-10 rounded-full bg-purple-800 absolute top-0 left-0 transform translate-x-4 translate-y-4 shadow-md"></div>
          <div className="w-10 h-10 rounded-full bg-purple-800 absolute bottom-0 right-0 transform -translate-x-6 -translate-y-5 shadow-md"></div>
        </div>
        <p className="w-full lg:w-1/2 font-poppins font-semibold text-sm sm:text-base md:text-lg lg:text-3xl text-center lg:text-left">
          “ClubChat is a platform designed to help students and academic clubs
          stay connected, organized, and engaged. From discovering clubs to
          promoting events, we simplify the process so you can focus on what
          matters."
        </p>

        <img
          src={"/assets/what_club.jpeg"}
          alt="ClubChat Illustration"
          className="w-48 sm:w-64 md:w-80 lg:w-96 rounded-2xl mx-auto lg:ml-12"
        />
      </div>
    </div>
  );
}

export default Value;
