import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cancelClubMemberRequestApi,
  SingleClubMemberApi,
  requestToJoinClubApi,
  updateClubApi,
  deleteClubApi,
} from "../../../../app/apiRequest/clubService";
import { openModal } from "../../../../app/common/modals/modalReducer";
import {
  Button,
  Confirm,
  Dropdown,
  Icon,
  Image,
  Item,
  Label,
  List,
} from "semantic-ui-react";
import { clearSelectedEvent } from "../../../events/eventReducer";
import UnauthModal from "../../../auth/UnauthModal";

export default function ClubAboutHeader({ club, channelId, isAdmin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { pathname } = useSelector((state) => state.router.location);
  const [member, setMember] = useState(null);
  const [openLeaveClubConfirm, setOpenLeaveClubConfirm] = useState(false);
  const [openDeleteClubConfirm, setOpenDeleteClubConfirm] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { loading } = useSelector((state) => state.async);
  const [isRestricted, setRestricted] = useState(false);

  var meta, isVerified;
  if (club.learningXClub) {
    meta = "LearningX club";
    isVerified = true;
  } else if (club.college && club.college_status === "verified") {
    meta = club.college.collegeName;
    isVerified = true;
  } else if (club.college && club.college_status !== "verified") {
    meta = club.college.collegeName;
    isVerified = false;
  } else meta = "";

  const marginTopValue =
    !pathname.includes("/club/about/") || !isAuthenticated ? 8 : 40;
  const styles = {
    marginTop: marginTopValue,
    marginLeft: 8,
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(SingleClubMemberApi(club._id, user)).then((response) => {
        if (response.length > 0) setMember(response[0]);
        else setMember(null);
      });
    }
  }, [club, user, dispatch, isAuthenticated]);

  useEffect(() => {
    if (currentUserProfile && club.college) {
      if (
        club.college.restricted &&
        !currentUserProfile.username.includes(club.college.emailDomain)
      ) {
        setRestricted(true);
      }
    }
  }, [currentUserProfile, club]);

  const handleJoinClub = () => {
    if (!isAuthenticated) {
      setModalOpen(true);
    } else if (member) {
      cancelClubMemberRequestApi(club._id, member.user._id);
      setMember(null);
    } else {
      const requestedMember = {
        club: club._id,
      };
      if (club.learningXClub) requestedMember.active = true;
      else requestedMember.active = false;
      requestToJoinClubApi(requestedMember).then((response) => {
        toast.success("Request has been sent!");
        setMember(response);
      });
    }
  };

  const handleCreateEvent = () => {
    dispatch(clearSelectedEvent());
    history.push(`/event/create/details?club=${club?._id}`);
  };

  const handleVerifyClub = () => {
    dispatch(updateClubApi({ ...club, college_status: "verified" })).then(
      () => {
        toast.success("updated sucessfully!");
      }
    );
  };

  const handleUnverifyClub = () => {
    dispatch(
      updateClubApi({
        ...club,
        college_status: "rejected",
        college: null,
        privacy: "private",
      })
    ).then(() => {
      toast.success("updated sucessfully!");
    });
  };

  const handleAddAsUnofficialClub = () => {
    dispatch(updateClubApi({ ...club, college_status: "unverified" })).then(
      () => {
        toast.success("updated sucessfully!");
      }
    );
  };

  const handleLeaveclub = () => {
    cancelClubMemberRequestApi(member.club, member.user._id).then(() => {
      setOpenLeaveClubConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  const handleDeleteClub = () => {
    dispatch(deleteClubApi(club._id)).then(() => {
      setOpenDeleteClubConfirm(false);
      history.push("/clubs");
      window.location.reload();
    });
  };

  const handleReportBtn = () => {
    if (!isAuthenticated) {
      setModalOpen(true);
    } else {
      dispatch(
        openModal({
          modalType: "ReportForm",
          modalProps: {
            type: "club",
            id: club._id,
          },
        })
      );
    }
  };

  return (
    <div>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <div style={styles}>
        <Item.Group>
          <Item>
            <Item.Content>
              <Image
                src={club.clubImg}
                size="small"
                floated="right"
                style={{ marginRight: "15vw" }}
              />
              <Item.Meta as="a" style={{ color: "blue", display: "block" }}>
                {meta}
                {meta && (
                  <Label
                    size="mini"
                    style={{ marginLeft: 10 }}
                    color={isVerified ? "green" : "red"}
                  >
                    <Icon name={isVerified ? "check circle" : "dont"} />{" "}
                    {isVerified ? "verified" : "not verified"}
                  </Label>
                )}
              </Item.Meta>
              <Item.Header>{club.clubName}</Item.Header>
              <Item.Description>
                <p style={{ width: 500 }}>{club.description}</p>
                <List horizontal relaxed style={{ display: "block" }}>
                  {club.website && (
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkify" />{" "}
                        <a href={club.website} target="#blank">
                          {club.website}
                        </a>
                      </List.Content>
                    </List.Item>
                  )}
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" />{" "}
                      <a href={`mailto:${club.email}`} target="#blank">
                        {club.email}
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
                {club.instagram && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="instagram" />{" "}
                        <a href={club.instagram} target="#blank">
                          {club.instagram}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
                {club.linkedIn && (
                  <List horizontal relaxed style={{ display: "block" }}>
                    <List.Item>
                      <List.Content>
                        <List.Icon name="linkedin" />{" "}
                        <a href={club.linkedIn} target="#blank">
                          {club.linkedIn}
                        </a>
                      </List.Content>
                    </List.Item>
                  </List>
                )}
              </Item.Description>
              <Item.Extra>
                <div>
                  {isAdmin && (
                    <Button
                      content="Create Event"
                      icon="calendar plus"
                      color="blue"
                      style={{ marginTop: 16 }}
                      onClick={handleCreateEvent}
                    />
                  )}
                  {!isAdmin && member && member.active && (
                    <Button
                      color="blue"
                      content="View all members"
                      icon="users"
                      style={{ marginTop: 30 }}
                      as={Link}
                      to={`/club/${club._id}/member/${channelId}`}
                    />
                  )}
                  {!isRestricted && !isAdmin && member && !member.active && (
                    <Button
                      color="red"
                      icon="close"
                      loading={loading}
                      content="cancel request"
                      style={{ marginTop: 30 }}
                      onClick={handleJoinClub}
                    />
                  )}
                  {!isRestricted && !isAdmin && !member && (
                    <Button
                      color="blue"
                      loading={loading}
                      icon="add circle"
                      content={
                        club.learningXClub
                          ? "join club"
                          : "Request to join club"
                      }
                      style={{ marginTop: 30 }}
                      onClick={handleJoinClub}
                    />
                  )}
                  {!isAdmin && !member && (
                    <Button
                      color="blue"
                      icon="eye"
                      content="View Admin"
                      style={{ marginTop: 30 }}
                      onClick={() => {
                        dispatch(
                          openModal({
                            modalType: "ViewAdminModal",
                            modalProps: {
                              admin: club.admin,
                            },
                          })
                        );
                      }}
                    />
                  )}
                  {!isAdmin && (
                    <Button
                      color="blue"
                      icon="exclamation triangle"
                      style={{ marginTop: 30 }}
                      onClick={handleReportBtn}
                    />
                  )}
                  {(isAdmin ||
                    club.college?.admin.some((item) => item === user)) && (
                    <Dropdown
                      icon="ellipsis vertical"
                      floating
                      button
                      className="icon"
                    >
                      <Dropdown.Menu>
                        {isAdmin && (
                          <Dropdown.Item
                            as={"a"}
                            href={`/club/manage/clubInfo/${club._id}`}
                            icon="edit"
                            text="Edit Club"
                          />
                        )}
                        {isAdmin && (
                          <Dropdown.Item
                            as={"a"}
                            href={`/club/manage/channels/${club._id}`}
                            icon="setting"
                            text="Manage Channels"
                          />
                        )}
                        {isAdmin && (
                          <Dropdown.Item
                            as={"a"}
                            href={`/club/manage/members/${club._id}`}
                            icon="users"
                            text="Manage Members"
                          />
                        )}
                        {club.college?.admin.some((item) => item === user) &&
                          club.college_status !== "verified" && (
                            <Dropdown.Item
                              as={"a"}
                              onClick={handleVerifyClub}
                              icon="check circle"
                              text="Verify college club"
                            />
                          )}
                        {club.college?.admin.some((item) => item === user) && (
                          <Dropdown.Item
                            as={"a"}
                            onClick={handleUnverifyClub}
                            icon="close"
                            text="Remove from College page"
                          />
                        )}
                        {club.college?.admin.some((item) => item === user) &&
                          club.college_status !== "unverified" && (
                            <Dropdown.Item
                              as={"a"}
                              onClick={handleAddAsUnofficialClub}
                              icon="add"
                              text="Add to college page as unofficial"
                            />
                          )}
                        {!(club.admin.length === 1) && (
                          <Dropdown.Item
                            as={"a"}
                            onClick={() => setOpenLeaveClubConfirm(true)}
                            icon="log out"
                            text="Leave Club"
                          />
                        )}
                        {isAdmin && (
                          <Dropdown.Item
                            as={"a"}
                            onClick={() => setOpenDeleteClubConfirm(true)}
                            icon="trash"
                            text="Delete Club"
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
      <Confirm
        open={openLeaveClubConfirm}
        size="mini"
        onCancel={() => setOpenLeaveClubConfirm(false)}
        onConfirm={handleLeaveclub}
      />
      <Confirm
        open={openDeleteClubConfirm}
        size="mini"
        header="Do you want to delete this club?"
        content="All data of this club will be deleted. And it can't be reversed."
        onCancel={() => setOpenDeleteClubConfirm(false)}
        onConfirm={handleDeleteClub}
      />
      {pathname.includes("/club/about/") && isAuthenticated && (
        <div className="go-back-btn">
          <Button
            content="Go back"
            icon="left arrow"
            labelPosition="left"
            onClick={() => history.goBack()}
          />
        </div>
      )}
    </div>
  );
}
