import React from "react";
import { useDispatch } from "react-redux";
import { Button, Feed, Header, Icon, Image, Segment } from "semantic-ui-react";
import { ControlBar, Player } from "video-react";
import { openModal } from "../../../../app/common/modals/modalReducer";
import { formatBytes } from "../../../../app/common/util/util";
import { formatDistance } from "date-fns";
import displayMentionsAndLinks from "../../../../app/common/text/handleMentionClick";

export default function Discussionitem({ chat }) {
  const dispatch = useDispatch();

  return (
    <div
      className={chat.parentChatId ? "normal-chat" : "post-container"}
      style={{ margin: 5 }}
    >
      <Feed>
        <Feed.Event>
          <Feed.Label image={chat.sender.userImg || "/assets/user.png"} />
          <Feed.Content>
            <Feed.Summary>
              <Feed.User>{chat.sender.displayName}</Feed.User>
              <Feed.Date>
                {formatDistance(new Date(chat.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
        <Feed.Content style={{ marginLeft: 50, marginTop: -10 }}>
          {chat.filetype === "link" ? (
            <Feed.Summary>
              <a
                href={chat.chat}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >
                {chat.chat}
              </a>
            </Feed.Summary>
          ) : (
            <Feed.Summary
              content={displayMentionsAndLinks(chat.chat)}
              style={{ whiteSpace: "pre-line" }}
            />
          )}
          {chat.filetype === "image" && (
            <Feed.Extra>
              {/* eslint-disable-next-line */}
              <a>
                <Image
                  size="small"
                  src={chat.file}
                  alt=""
                  onClick={(event) => {
                    dispatch(
                      openModal({
                        modalType: "FullSizeImgModal",
                        modalProps: { id: 0, images: [chat.file] },
                      })
                    );
                  }}
                />
              </a>
            </Feed.Extra>
          )}
          {chat.filetype === "video" && (
            <Feed.Extra>
              {/* eslint-disable-next-line */}
              <a>
                <Player playsInline fluid={false} width="100%" height="100%">
                  <source src={chat.file} />
                  <ControlBar autoHide={true} />
                </Player>
              </a>
            </Feed.Extra>
          )}
          {chat.filetype === "file" && (
            <Feed.Extra>
              {/* eslint-disable-next-line */}
              <Segment compact>
                <Header as="h3">
                  <Icon name="file" color="blue" />
                  <Header.Content>
                    {chat.filename}
                    <Button
                      icon="download"
                      as="a"
                      href={chat.file}
                      style={{ marginLeft: 20 }}
                    />
                    <Header.Subheader>
                      {formatBytes(chat.filesize)} {chat.realFiletype}{" "}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </Feed.Extra>
          )}
        </Feed.Content>
      </Feed>
    </div>
  );
}
