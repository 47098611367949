import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Card,
  Grid,
  Icon,
  Menu,
  Segment,
  Header,
  Button,
  List,
  Image,
} from "semantic-ui-react";
import { fetchClubsApi } from "../../app/apiRequest/clubService";
import { fetchCollegesApi } from "../../app/apiRequest/collegeFestService";
import { fetchEventsApi } from "../../app/apiRequest/eventService";
import { fetchProfilesApi } from "../../app/apiRequest/profileService";
import {
  asyncActionError,
  asyncActionFinish,
} from "../../app/async/asyncReducer";
import LoadingComponent from "../../app/layout/LoadingComponent";
import ClubListItem from "../club/clubDashboard/ClubListItem";
import EventListItem from "../events/eventDashboard/EventListIem";
import { addProfiles, SEARCH_RETAIN_STATE } from "./searchReducer";
import { Helmet } from "react-helmet";

export default function SearchDashboard({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("club");
  const query = location.search.split("=");
  const { loading, error } = useSelector((state) => state.async);
  const { value, profiles, colleges, clubs, events, retainState } = useSelector(
    (state) => state.search
  );

  useEffect(() => {
    if (retainState) return;
    if (activeItem === "club") {
      // eslint-disable-next-line
      dispatch(fetchClubsApi(`?$text[$search]=\"${value}\"`));
    } else if (activeItem === "people") {
      // eslint-disable-next-line
      dispatch(fetchProfilesApi(`?$text[$search]=\"${value}\"`))
        .then((response) => {
          dispatch(addProfiles(response));
          dispatch(asyncActionFinish());
        })
        .catch((error) => dispatch(asyncActionError(error.message)));
    } else if (activeItem === "college") {
      // eslint-disable-next-line
      dispatch(fetchCollegesApi(`?$text[$search]=\"${value}\"`));
    } else if (activeItem === "event") {
      // eslint-disable-next-line
      dispatch(fetchEventsApi(`?stepsDone=6&$text[$search]=\"${value}\"`));
    } else {
      return;
    }
    return () => dispatch({ type: SEARCH_RETAIN_STATE });
  }, [activeItem, retainState, query[1]]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    history.push(`/search?${name}=${query[1]}`);
  };

  if (loading && !error) return <LoadingComponent content="Loading ..." />;

  if (error) return <Redirect to={"/error"} />;

  return (
    <>
      <Grid>
        <Grid.Column width={12}>
          <div style={{ marginTop: 45 }}>
            <Menu pointing secondary fixed="top">
              <Menu.Item
                name="club"
                active={activeItem === "club"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="people"
                active={activeItem === "people"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="college"
                active={activeItem === "college"}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="event"
                active={activeItem === "event"}
                onClick={handleItemClick}
              />
            </Menu>
            <div>
              {activeItem === "club" && clubs.length > 0 && (
                <div className="card-container">
                  {clubs.map((club) => (
                    <ClubListItem club={club} key={club._id} />
                  ))}
                </div>
              )}
              {activeItem === "event" &&
                events.length > 0 &&
                events.map((event) => (
                  <EventListItem event={event} key={event._id} />
                ))}
              {activeItem === "college" && colleges.length > 0 && (
                <Card.Group itemsPerRow={3}>
                  {colleges.map((college) => (
                    <Card
                      key={college._id}
                      image={college.collegeImg}
                      header={college.collegeName}
                      meta={college.city.address}
                      description={
                        <p className="three-lines">{college.description}</p>
                      }
                      extra={
                        <>
                          <Icon name="university" />
                          College
                        </>
                      }
                      onClick={() => history.push(`/college/${college._id}`)}
                    />
                  ))}
                </Card.Group>
              )}
              {activeItem === "people" &&
                profiles.length > 0 &&
                profiles.map((profile) => (
                  <List selection key={profile._id}>
                    <List.Item as={Link} to={`/profile/${profile._id}`}>
                      <Image avatar src={profile.userImg} />
                      <List.Content>
                        <List.Header>{profile.displayName}</List.Header>
                        <List.Description>{profile.user_name}</List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                ))}
              {((activeItem === "club" && clubs.length === 0) ||
                (activeItem === "event" && events.length === 0) ||
                (activeItem === "people" && profiles.length === 0) ||
                (activeItem === "college" && colleges.length === 0)) && (
                <Segment placeholder>
                  <Header icon>
                    <Icon name="question circle" />
                    No results found.
                  </Header>
                  <Button as={Link} to="/clubs" primary>
                    Go to Home page
                  </Button>
                </Segment>
              )}
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <Helmet>
        <title>{"Search"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </>
  );
}
