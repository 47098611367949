import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Card, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { fetchSingleClubApi } from "../../../../app/apiRequest/clubService";
import { openModal } from "../../../../app/common/modals/modalReducer";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClubNavMenu from "../ClubNavMenu";
import { clearSessions } from "./clubSessionReducer";
import SessionListItem from "./SessionListItem";
import { fetchChannelSessionsApi } from "../../../../app/apiRequest/channelService";
import { listenToSelectedChannel } from "../../clubReducer";

export default function SessionDashboard({ match }) {
  const dispatch = useDispatch();
  const { isLoading, upcomingSessions, recentlySessions } = useSelector(
    (state) => state.session
  );
  const { loading, error } = useSelector((state) => state.async);
  const { selectedClub, selectedChannel } = useSelector((state) => state.club);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (selectedClub?._id === match.params.clubId) {
      dispatch(clearSessions());
      dispatch(fetchChannelSessionsApi(match.params.channelId, ""));
      return;
    }
    dispatch(fetchSingleClubApi(match.params.clubId)).then(() => {
      dispatch(clearSessions());
      dispatch(fetchChannelSessionsApi(match.params.channelId, ""));
    });
    // eslint-disable-next-line
  }, [dispatch, match.params.channelId, match.params.clubId]);

  useEffect(() => {
    if (selectedClub) {
      for (const channel of selectedClub.channels) {
        if (channel._id === match.params.channelId)
          dispatch(listenToSelectedChannel(channel));
      }
    }
  }, [dispatch, selectedClub, match.params.channelId]);

  if (loading || !selectedChannel)
    return <LoadingComponent content="Loadind club..." />;

  if (error) return <Redirect to={"/error"} />;

  const isAdmin = selectedChannel?.admin.includes(user);

  return (
    <div className="club-detailed-page">
      <ClubNavMenu club={selectedClub} channelId={match.params.channelId} />
      <Header
        content="Upcoming"
        icon="clock"
        style={{ marginBottom: 20 }}
      />
      {upcomingSessions.length === 0 && (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell" />
            You have no upcoming session.
          </Header>
        </Segment>
      )}
      <Card.Group>
        <Card.Group>
          {upcomingSessions.map((session) => (
            <SessionListItem
              session={session}
              key={session._id}
              isAdmin={isAdmin}
            />
          ))}
        </Card.Group>
        <Loader active={loading} />
      </Card.Group>
      <Header content="Recently" icon="check square" />
      <Card.Group>
        {recentlySessions.map((session) => (
          <SessionListItem
            session={session}
            key={session._id}
            isAdmin={isAdmin}
          />
        ))}
        <Loader active={isLoading} />
      </Card.Group>
      {isAdmin && (
        <Button
          content="Create Session"
          icon="add"
          color="blue"
          style={{ position: "fixed", right: 50, top: 140 }}
          onClick={() =>
            dispatch(
              openModal({
                modalType: "CreateSessionsForm",
                modalProps: { club: selectedClub, channel: selectedChannel },
              })
            )
          }
        />
      )}
    </div>
  );
}
