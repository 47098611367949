import React from "react";
import Social from "./Social";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";

function FooterPage() {
  const dispatch = useDispatch();
  return (
    <div
      className="pt-10 px-4 md:px-20 lg:px-32 bg-gray-900 w-full overflow-hidden"
      id="Footer"
    >
      <div className="footer-container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Club Chat Section */}
        <div className="text-center md:text-left">
          <div className="flex items-center justify-center md:justify-start gap-2">
            <img src="/logo.png" alt="Logo" className="h-8 w-8" />
            <span className="text-3xl font-medium text-white">Club Chat</span>
          </div>

          <ul className="mt-4 text-gray-400 space-y-2 list-disc list-inside">
            <li>
              Innovation: We strive to be at the forefront of educational
              technology, continually improving our offerings to meet the
              evolving needs of learners.
            </li>
            <li>
              Accessibility: We are committed to making education accessible to
              all, regardless of geographic or socioeconomic barriers.
            </li>
            <li>
              Collaboration: We believe in the power of collaboration and work
              closely with educators, institutions, and technology partners to
              create the best learning experiences.
            </li>
          </ul>
        </div>

        {/* Company Links Section */}
        <div className="text-center">
          <h3 className="text-white text-lg font-bold mb-4">Company</h3>
          <ul className="flex flex-col gap-2 text-gray-400">
            <a href="/about" className="hover:text-white">
              About us
            </a>
            <a
              href="#Contact"
              onClick={() =>
                dispatch(openModal({ modalType: "ContactUsForm" }))
              }
              className="hover:text-white"
            >
              Contact us
            </a>
            <a href="/privacy-policy" className="hover:text-white">
              Privacy Policy
            </a>
            <a href="/terms-conditions" className="hover:text-white">
              Terms and Connditions
            </a>
          </ul>
        </div>

        {/* Download App Section */}
        <div className="text-center md:text-left">
          <h2 className="text-white text-lg font-bold mb-4">
            DOWNLOAD THE APP
          </h2>
          <div className="flex flex-row gap-4 justify-center items-center md:justify-start">
            <a href="https://play.google.com/store/apps/details?id=in.learningx.club_app">
              <img
                className="border rounded-lg w-64"
                src={"/images/images 2.svg"}
                alt="Play Store"
              />
            </a>
            <a href="https://apps.apple.com/in/app/clubchat-academic-club-more/id6612034727">
              <img
                className="border rounded-lg w-64"
                src={"/images/images 1.svg"}
                alt="App Store"
              />
            </a>
          </div>

          <div className="mt-4">
            <Social />
          </div>
        </div>
      </div>

      {/* Information Section */}
      <div className="mt-8 text-center text-gray-100">
        For more information about our programs and partnerships, please visit
        our website or contact us at{" "}
        <a
          href={`mailto:contact@clubchat.live`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-300 hover:text-blue-500 transition-colors duration-300"
        >
          contact@clubchat.live
        </a>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-700 py-4 mt-10 text-center text-gray-500">
        Copyright 2025 © Clubsphere Online Innovation Pvt. Ltd. All Rights
        Reserved.
      </div>
    </div>
  );
}

export default FooterPage;
