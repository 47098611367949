/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Icon, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import {
  azureSignIn,
  googleSignIn,
  loginUser,
} from "../../app/apiRequest/loginService";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PublicClientApplication } from "@azure/msal-browser";

export default function LoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          console.log("Authentication successful:", response);
          dispatch(azureSignIn(response.idToken)).then(() =>
            window.location.reload()
          );
        } else {
          console.log("No response from authentication.");
        }
      } catch (error) {
        console.error("Error handling redirect response:", error);
        // Handle error, such as displaying an error message to the user
      }
    };

    handleRedirect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const google = window.google;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      use_fedcm_for_prompt: true, // Enable FedCM for the prompt
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      type: "standard",
      size: "large",
      shape: "rectangular",
      text: "signin",
      width: "120",
      theme: "outline",
    });
    // eslint-disable-next-line
  }, []);

  function handleCallbackResponse(response) {
    dispatch(googleSignIn(response.credential)).then(() =>
      window.location.reload()
    );
  }

  const handleForgotPassBtn = () => {
    dispatch(openModal({ modalType: "ForgotPasswordForm" }));
  };

  const handleRegisterBtn = () => {
    dispatch(openModal({ modalType: "RegisterForm" }));
  };

  const handleAzureSignIn = async () => {
    history.push("/");

    try {
      msalInstance.loginRedirect({
        redirectUri: process.env.REACT_APP_DOMAIN_URL,
        scopes: ["openid", "profile", "User.Read", "email", "offline_access"],
      });
    } catch (error) {
      console.error("Error during authentication:", error);
      // Handle error, such as showing an error message to the user
    }
  };

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  return (
    <ModalWrapper size="tiny" header="Sign In">
      <div className="login-content flex">
        <div className="contentDiv flex">
          <h2 className="contentText">Discover college, clubs and events</h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            try {
              dispatch(loginUser(values, setErrors)).then(() =>
                window.location.reload()
              );
            } catch (error) {
              setErrors({ auth: "Error in Login server!" });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form className="ui login form">
              <MyTextInput name="username" placeholder="Email Address" />
              <MyTextInput
                name="password"
                placeholder="Password"
                type="password"
              />
              {errors.auth && (
                <Label
                  basic
                  color="red"
                  style={{ marginBottom: 10 }}
                  content={errors.auth}
                />
              )}
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                fluid
                size="large"
                color="blue"
                content="Login"
              />
            </Form>
          )}
        </Formik>
        <div className="links flex">
          <a href="#" onClick={handleForgotPassBtn}>
            Forget your password?
          </a>
          <p id="loginWith">or login with</p>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: -10 }}
          >
            <div id="signInDiv" style={{ marginRight: "10px" }} />{" "}
            {/* Add margin if needed */}
            <Button color="black" basic onClick={handleAzureSignIn}>
              <Icon name="microsoft" /> Outlook
            </Button>
          </div>
          <p>
            Don't have an account?{" "}
            <a href="#" onClick={handleRegisterBtn}>
              create now
            </a>
          </p>
        </div>
      </div>
    </ModalWrapper>
  );
}
