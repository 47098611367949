import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Image, Input, Label, Icon } from "semantic-ui-react";
import { fetchUserFeedApi } from "../../../app/apiRequest/chatService";
import _ from "lodash";
import { createUserFeed } from "../chatReducer";

export default function UserFeedDashboard({ match }) {
  const timeoutRef = React.useRef();
  const dispatch = useDispatch();
  const { userfeed, selectedChat } = useSelector((state) => state.chats);
  const currentUser = useSelector((state) => state.auth.user);
  const [profiles, setProfiles] = useState([]);
  const [a, setA] = useState([]);

  useEffect(() => {
    if (userfeed.length > 0) return;
    dispatch(fetchUserFeedApi(currentUser)).then(() => {
      if (selectedChat?.newChat) {
        dispatch(createUserFeed(selectedChat));
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (userfeed.length > 0) {
      for (let room of userfeed) {
        if (room.users[0]._id === currentUser) {
          setA((oldArray) => [
            ...oldArray,
            {
              ...room.users[1],
              lastChat: room.lastChat,
              unreadCount: room.unreadCount,
            },
          ]);
          setProfiles((oldArray) => [
            ...oldArray,
            {
              ...room.users[1],
              lastChat: room.lastChat,
              unreadCount: room.unreadCount,
            },
          ]);
        } else {
          setA((oldArray) => [
            ...oldArray,
            {
              ...room.users[0],
              lastChat: room.lastChat,
              unreadCount: room.unreadCount,
            },
          ]);
          setProfiles((oldArray) => [
            ...oldArray,
            {
              ...room.users[0],
              lastChat: room.lastChat,
              unreadCount: room.unreadCount,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line
  }, [userfeed]);

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          setA([...profiles]);
          return;
        }

        const re = new RegExp(_.escapeRegExp(data.value), "i");
        setA(profiles.filter((el) => re.test(el.firstname)));
      }, 300);
    },
    [profiles]
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      id={
        match.params.id
          ? "selected-chat-left-menu"
          : "unselected-chat-left-menu"
      }
    >
      <Input
        fluid
        icon="search"
        onChange={handleSearchChange}
        placeholder="Search..."
        style={{ margin: 10 }}
      />
      <div style={{ margin: 10 }}>
        {a.length > 0 &&
          a.map((user) => (
            <List key={user._id} selection verticalAlign="middle">
              <List.Item
                onClick={() => (window.location = `/chats/${user._id}`)}
              >
                <Image
                  src={user.userImg}
                  size="mini"
                  avatar
                  verticalAlign="middle"
                />
                <List.Content>
                  <List.Header>
                    {user.displayName}{" "}
                    {user.admin && <Icon name="check circle" color="blue" />}
                    {user.unreadCount !== 0 && (
                      <Label
                        color="blue"
                        size="mini"
                        circular
                        style={{ marginLeft: 5 }}
                      >
                        {user.unreadCount}
                      </Label>
                    )}
                  </List.Header>
                  <List.Description className="twenty-chars">
                    {user.lastChat}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          ))}
      </div>
    </div>
  );
}
