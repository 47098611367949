import React from "react";
import ClubFileListItem from "./ClubFilesListItem";
import InfiniteScroll from "react-infinite-scroller";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableBody,
  Table,
  Icon,
} from "semantic-ui-react";

export default function ClubFilesList({
  files,
  getNextFiles,
  loading,
  moreFiles,
}) {
  return (
    <>
      {files.length !== 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextFiles}
          hasMore={!loading && moreFiles}
          initialLoad={false}
        >
          <Table basic="very">
            <TableHeader>
              <TableRow>
                <TableHeaderCell>
                  <Icon name="file" />
                </TableHeaderCell>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Size</TableHeaderCell>
                <TableHeaderCell>Modified</TableHeaderCell>
                <TableHeaderCell>Modified By</TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {files.map((file) => (
                <ClubFileListItem file={file} key={file._id} />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      )}
    </>
  );
}
