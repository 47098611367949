import React from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Header, Icon } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import FooterPage from "../about/FooterPage";
import { Helmet } from "react-helmet";

export default function HeplPage() {
  const dispatch = useDispatch();

  const handleForm = (e, { name }) => {
    dispatch(openModal({ modalType: name }));
  };
  return (
    <div style={{ margin: 20 }}>
      <Card.Group>
        <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="text telephone" />
              Contact us
            </Header>
          </Card.Content>
          <Card.Content extra textAlign="'center">
            <Button primary name="ContactUsForm" onClick={handleForm}>
              contact us
            </Button>
          </Card.Content>
        </Card>
        <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="university" />
              Make your college page on Club-Chat
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="CollegePageRequest" onClick={handleForm}>
              Create page
            </Button>
          </Card.Content>
        </Card>
        <Card style={{ paddingTop: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="adversal" />
              Fill form to list your college and Collegefest in Featured
              oppurtunities on HOME page
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="FeaturedAdForm" onClick={handleForm}>
              Fill form
            </Button>
          </Card.Content>
        </Card>
        {/* <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="user" />
              Apply for becoming Guest Mentor on Club-Chat club
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="GuestMentorForm" onClick={handleForm}>
              Apply here
            </Button>
          </Card.Content>
        </Card> */}
        <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="comment alternate" />
              Give feedback about your Leaningx experiance
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="FeedbackForm" onClick={handleForm}>
              Give feedback
            </Button>
          </Card.Content>
        </Card>
        <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="exclamation triangle" />
              Let us know about a broken feature
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="BugReportForm" onClick={handleForm}>
              Report problems
            </Button>
          </Card.Content>
        </Card>
        <Card style={{ padding: 40, margin: 10 }}>
          <Card.Content>
            <Header icon>
              <Icon name="announcement" />
              Suggest a feature
            </Header>
          </Card.Content>
          <Card.Content textAlign="'center" extra>
            <Button primary name="SuggestFeatureForm" onClick={handleForm}>
              Suggest feature
            </Button>
          </Card.Content>
        </Card>
      </Card.Group>
      <div style={{ marginTop: 40 }}>
        <FooterPage />
      </div>
      <Helmet>
        <title>{"Help & Setting"}</title>
        <meta name="description" content={"Club-Chat"} />
      </Helmet>
    </div>
  );
}
