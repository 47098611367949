import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

export default function ErrorComponent() {
  const { error } = useSelector((state) => state.async);

  return (
    <Segment placeholder>
      <Header textAlign="center" icon>
        <Icon name="question circle" />
        {error || "Oops - we have an error"}
      </Header>
      <Button
        as={Link}
        to="/"
        primary
        style={{ marginTop: 20 }}
        content="Return to Home page"
      />
    </Segment>
  );
}
