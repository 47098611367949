import React from 'react';

function About() {
    return (
        <div className="flex flex-col items-center px-4">
            <div className="flex items-center mt-24 mb-10 text-center">
                <div className="w-10 h-10 rounded-full bg-purple-800 mr-4"></div>
                <div className="text-purple-900 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold font-poppins">
                    What is ClubChat?
                </div>
            </div>

            <div className="flex flex-col lg:flex-row bg-[rgba(81,59,131,0.4)] w-full lg:w-[70%] h-auto rounded-2xl text-black p-6 md:p-8 mt-5 items-center justify-center gap-6 lg:gap-12">
                <p className="w-full lg:w-1/2 font-poppins font-semibold text-sm sm:text-base md:text-lg lg:text-2xl text-center lg:text-left">
                    ClubChat is the ultimate platform for students to connect with
                    academic clubs and campus events. Discover clubs that match your
                    interests, RSVP to events, and stay updated. With a real-time chat
                    feature, members can communicate and collaborate effortlessly within
                    their clubs. Club leaders can promote events and grow their
                    communities with ease.
                </p>

                <img
                    src={"/assets/what_club.jpg"}
                    alt="ClubChat Illustration"
                    className="w-48 sm:w-64 md:w-80 lg:w-96 rounded-2xl mx-auto lg:ml-12"
                />
            </div>
        </div>
    );
}

export default About;
