import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/common/modals/modalReducer";
export default function SignedOutMenu() {
  const dispatch = useDispatch();
  return (
    <nav>
      <div className="logo-btns">
        <a className="menu-item" href="/">
          <img src="/logo.png" alt="logo" className="logo" />
          Club-Chat
        </a>
        <div className="btns">
          <div
            className="Login"
            onClick={() => dispatch(openModal({ modalType: "LoginForm" }))}
          >
            Log In
          </div>
          <div
            className="Signup"
            onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
          >
            Sign Up
          </div>
        </div>
      </div>
    </nav>
  );
}
