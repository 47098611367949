import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";

export default function ReminderEventList({ events }) {
  return (
    <div>
      {events.length > 0 ? (
        events.map((event) => (
          <Segment>
            <Grid as={Link} to={`/events/${event._id}`}>
              <Grid.Column width={6}>
                <Image
                  src={event.eventImg || "https://learningx-s3.s3.ap-south-1.amazonaws.com/image_850_315.png"}
                  style={{ height: 125 }}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as="h2">
                  {event.eventTitle}
                  <Header.Subheader>
                    {event.club.college?.collegeName ?? event.club?.clubName}
                  </Header.Subheader>
                </Header>
                <List>
                  <List.Item>
                    <List.Icon name="clock" />
                    <List.Content>
                      {new Date(event.registrationEndDate).toDateString(
                        "MMM dd yyyy"
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="users" />
                    <List.Content>
                      {event.registerdTeamLead.length} members registered
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="rupee sign" />
                    <List.Content>{event.totalRewards}</List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </Segment>
        ))
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="bell" />
            You have no upcoming events.
          </Header>
          <Button as={Link} to="/events" primary>
            Go to Event Page
          </Button>
        </Segment>
      )}
    </div>
  );
}
