import React, { useState, useEffect } from "react";

function Benefits() {
  const [activeHeader1, setActiveHeader1] = useState(0);
  const [activeHeader2, setActiveHeader2] = useState(0);

  const headers1 = [
    {
      id: 0,
      title: "Centralized Hub",
      description:
        "Organize your club’s activities, manage member participation, and foster a strong sense of community—all with just a few clicks.",
      img: "/assets/1.png",
    },
    {
      id: 1,
      title: "Easy Communication",
      description:
        "Keep your members engaged with group chats, announcements, and direct messaging to ensure everyone stays in the loop.",
      img: "/assets/2.png",
    },
    {
      id: 2,
      title: "Create & Manage Events",
      description:
        "Promote your club’s events with ease. Create an event, set up reminders, and track RSVPs—all in one place.",
      img: "/assets/3.png",
    },
    {
      id: 3,
      title: "Reach More Students",
      description:
        "Expand your club’s presence both on and off-campus by easily sharing events and updates with a broader student audience.",
      img: "/assets/4.png",
    },
  ];

  const headers2 = [
    {
      id: 0,
      title: "Find Academic Clubs",
      description:
        "Explore a variety of clubs and organizations that align with your interests, from tech to art, sports to entrepreneurship.",
      img: "/assets/5.png",
    },
    {
      id: 1,
      title: "Chat with Club Members",
      description:
        "Engage in lively discussions, share ideas, and stay updated with real-time notifications, all within a single platform.",
      img: "/assets/6.png",
    },
    {
      id: 2,
      title: "Join with a Click",
      description:
        "Joining a club has never been easier. Browse through the options, choose what excites you, and become part of the community instantly.",
      img: "/assets/7.png",
    },
    {
      id: 3,
      title: "Event Calendar",
      description:
        "Never miss an event! Stay informed about academic events, competitions, seminars, and workshops happening on your campus or across institutions.",
      img: "/assets/8.png",
    },
  ];

  useEffect(() => {
    const slider1 = document.querySelector(".slider-container-1");
    const slider2 = document.querySelector(".slider-container-2");

    let currentIndex1 = 0;
    let currentIndex2 = 0;

    const interval1 = setInterval(() => {
      currentIndex1 = (currentIndex1 + 1) % headers1.length;
      slider1.scrollTo({
        left: slider1.clientWidth * currentIndex1,
        behavior: "smooth",
      });
      setActiveHeader1(currentIndex1);
    }, 3000); // Change every 3 seconds for headers1

    const interval2 = setInterval(() => {
      currentIndex2 = (currentIndex2 + 1) % headers2.length;
      slider2.scrollTo({
        left: slider2.clientWidth * currentIndex2,
        behavior: "smooth",
      });
      setActiveHeader2(currentIndex2);
    }, 3000); // Change every 3 seconds for headers2

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [headers1.length, headers2.length]);

  const handleDescriptionClick = (section, id) => {
    if (section === 1) {
      setActiveHeader1(id);
      const slider1 = document.querySelector(".slider-container-1");
      slider1.scrollTo({
        left: slider1.clientWidth * id,
        behavior: "smooth",
      });
    } else if (section === 2) {
      setActiveHeader2(id);
      const slider2 = document.querySelector(".slider-container-2");
      slider2.scrollTo({
        left: slider2.clientWidth * id,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="benefits">
      {/* Section 1: For Club Heads */}
      <div className="text-center mb-6">
        <h1 className="text-purple-900 text-4xl lg:text-6xl font-semibold font-poppins">
          For Club Heads
        </h1>
        <p className="text-purple-800 text-lg lg:text-xl">
          Promote, Organize, Lead
        </p>
      </div>

      <div className="container flex flex-col lg:flex-row lg:items-center lg:gap-12 mt-8">
        <div className="w-full lg:w-2/3 flex flex-col items-center">
          {/* Horizontal Slider */}
          <div className="slider-container slider-container-1 relative w-full overflow-x-auto flex snap-x snap-mandatory scroll-smooth">
            {headers1.map((header, index) => (
              <div
                key={header.id}
                className="slider-item w-full min-w-full flex-shrink-0 snap-center flex justify-center items-center"
              >
                <img
                  src={header.img}
                  alt={`Benefits illustration ${header.title}`}
                  className="w-full max-w-md lg:max-w-lg rounded shadow-md"
                />
              </div>
            ))}
          </div>

          {/* Dot Indicators */}
          <div className="dot-container flex justify-center mt-4 gap-2">
            {headers1.map((_, index) => (
              <span
                key={index}
                className={`h-3 w-3 rounded-full ${
                  activeHeader1 === index ? "bg-blue-700" : "bg-gray-400"
                }`}
              ></span>
            ))}
          </div>
        </div>

        {/* Description */}
        <div className="right-container flex flex-col gap-4 lg:w-1/3">
          {headers1.map((header) => (
            <div
              key={header.id}
              className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
                activeHeader1 === header.id ? "bg-blue-100" : "bg-white"
              } hover:bg-blue-50`}
              onClick={() => handleDescriptionClick(1, header.id)}
            >
              <div className="flex items-center gap-4">
                <button
                  style={{
                    backgroundColor:
                      activeHeader1 === header.id
                        ? "rgba(243, 128, 34, 1)"
                        : "",
                    color: activeHeader1 === header.id ? "white" : "",
                  }}
                  aria-label={header.title}
                >
                  {header.id + 1}
                </button>
                <h2
                  className="text-lg lg:text-xl font-semibold"
                  style={{ marginTop: 0 }}
                >
                  {header.title}
                </h2>
              </div>
              <p className="mt-2 text-gray-600">{header.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Section 2: For Students */}
      <div className="text-center mt-16 mb-6">
        <h1 className="text-purple-900 text-4xl lg:text-6xl font-semibold font-poppins">
          For Students
        </h1>
        <p className="text-purple-800 text-lg lg:text-xl">Join, Connect</p>
      </div>

      <div className="container flex flex-col lg:flex-row lg:items-center lg:gap-12 mt-8">
        <div className="w-full lg:w-2/3 flex flex-col items-center">
          {/* Horizontal Slider */}
          <div className="slider-container slider-container-2 relative w-full overflow-x-auto flex snap-x snap-mandatory scroll-smooth">
            {headers2.map((header, index) => (
              <div
                key={header.id}
                className="slider-item w-full min-w-full flex-shrink-0 snap-center flex justify-center items-center"
              >
                <img
                  src={header.img}
                  alt={`Benefits illustration ${header.title}`}
                  className="w-full max-w-md lg:max-w-lg rounded shadow-md"
                />
              </div>
            ))}
          </div>

          {/* Dot Indicators */}
          <div className="dot-container flex justify-center mt-4 gap-2">
            {headers2.map((_, index) => (
              <span
                key={index}
                className={`h-3 w-3 rounded-full ${
                  activeHeader2 === index ? "bg-blue-700" : "bg-gray-400"
                }`}
              ></span>
            ))}
          </div>
        </div>

        {/* Description */}
        <div className="right-container flex flex-col gap-4 lg:w-1/3">
          {headers2.map((header) => (
            <div
              key={header.id}
              className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out ${
                activeHeader2 === header.id ? "bg-blue-100" : "bg-white"
              } hover:bg-blue-50`}
              onClick={() => handleDescriptionClick(2, header.id)}
            >
              <div className="flex items-center gap-4">
                <button
                  style={{
                    backgroundColor:
                      activeHeader2 === header.id
                        ? "rgba(243, 128, 34, 1)"
                        : "",
                    color: activeHeader2 === header.id ? "white" : "",
                  }}
                  aria-label={header.title}
                >
                  {header.id + 1}
                </button>
                <h2
                  className="text-lg lg:text-xl font-semibold"
                  style={{ marginTop: 0 }}
                >
                  {header.title}
                </h2>
              </div>
              <p className="mt-2 text-gray-600">{header.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Benefits;
